import Container from 'src/components/_layout/Container/Container'
import { StyledRichText } from './RichText.style'
import Padder from 'src/components/_layout/Padder/Padder'
import Text from 'src/components/atoms/Text/Text'
import AtomRichText from 'src/components/atoms/RichText'
import Revealer from 'src/components/helpers/Revealer/Revealer'
type RichTextProps = {
    htmlString: string
    title: string
}

const RichText: React.FC<RichTextProps> = ({ htmlString, title }) => {
    return (
        <StyledRichText as={Container}>
            <Revealer>
                <Padder top="md">
                    {!!title && (
                        <Text
                            textVariant="heading3"
                            dangerouslySetInnerHTML={{ __html: title }}
                            css={{ color: '$primary', fontWeight: '$normal' }}
                        />
                    )}
                    {!!htmlString && (
                        <AtomRichText
                            htmlString={htmlString}
                            textVariant="base2"
                        />
                    )}
                </Padder>
            </Revealer>
        </StyledRichText>
    )
}

export default RichText
