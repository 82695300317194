import { styled } from 'src/styles/stitches.config'

export const StyledBookingFormText = styled('div', {
    '@md': {
        marginTop: 'calc(-2 * $colWidth)!important',
    },
})

export const StyledColumn = styled('div', {
    '@md': {
        width: '50%',
    },
})
