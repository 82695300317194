import { useEffect } from 'react'

import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

import { StyledImageReveal } from './ImageReveal.style'

interface ImageRevealProps {
    src: string
    alt?: string
}

const ImageReveal: React.FC<ImageRevealProps> = ({ src, alt }) => {
    const [ref, inView, entry] = useInView({
        triggerOnce: true,
        threshold: 0.25,
    })
    const controls = useAnimation()
    useEffect(() => {
        if (!!entry && inView) {
            controls.start({
                y: ['100px', '0px'],
                scale: [1.15, 1],
                opacity: 1,
                transition: {
                    duration: 1,
                    ease: 'easeInOut',
                },
            })
        }
    }, [entry, inView])

    return (
        <>
            <StyledImageReveal
                as={motion.img}
                src={src}
                alt={alt}
                ref={ref}
                animate={controls}
            />
        </>
    )
}

export default ImageReveal
