import { styled } from 'src/styles/stitches.config'

export const StyledImageReveal = styled('div', {
    transform: 'scale(1.15)',
    transformOrigin: 'center',
    objectFit: 'cover',
    opacity: 0,
    maxWidth: '100%',
    height: 'auto',
})
