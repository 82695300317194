import { Link } from 'react-router-dom'
import Text from 'src/components/atoms/Text/Text'
import { MenuItemType } from 'src/state/settings/types'

export const getLinkComponent = (item: MenuItemType) => {
    const { url } = item
    const targetValue = url.startsWith('http') ? '_blank' : undefined
    const isSpecialLink = url.startsWith('mailto') || url.startsWith('tel')
    const isA = isSpecialLink || targetValue === '_blank'
    const to = isA
        ? {
              href: url,
          }
        : {
              to: url,
          }
    return (
        <Text
            key={item.ID}
            as={isA ? 'a' : Link}
            {...to}
            target={targetValue}
            textVariant="base"
            css={{ fontWeight: '$bold' }}
            align={{
                '@initial': 'center',
                '@md': 'left',
            }}
        >
            {item.title}
        </Text>
    )
}
