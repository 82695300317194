import { styled } from 'src/styles/stitches.config'

export const StyledHomeSlider = styled('div', {
    height: '50vh',
    '@md': {
        height: '92.5vh',
        minHeight: 750,
        overflow: 'hidden',
    },
})

export const SliderContainer = styled('div', {
    height: '100%',

    '@mdDown': {
        paddingTop: '5rem',
    },
})

export const SliderWrapper = styled('div', {
    position: 'relative',
    width: '100%',
    height: '100%',
    gridColumnStart: 2,

    '.swiper-slide': {
        width: '100%',
        height: '100%',

        img: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center bottom',
        },
    },
})

export const InfoWrapper = styled('div', {
    position: 'relative',
    gridColumnStart: 1,
    width: '100%',
    zIndex: 2,
    color: '$white',
    '@md': {
        width: '130%',
    },
})

export const NavButton = styled('button', {
    fontSize: '2rem',
})
