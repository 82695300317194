import { styled } from 'src/styles/stitches.config'

export const StyledRichText = styled('p', {
    'strong, b, h1, h2, h3, h4, h5, h6': {
        fontWeight: '$bold',
    },
    em: {
        fontStyle: 'italic',
    },
    p: {
        marginBottom: '$xxs',
    },
    ul: {
        listStyle: 'inside',
    },
    h4: {
        fontFamily: '$secondary',
        fontWeight: '$normal',
        lineHeight: 1,
        fontSize: '2.5rem',
        marginBottom: '$md',
        '@mdDown': {
            fontSize: '2rem',
            marginBottom: '$xs',
        },
        color: '$primary',
    },
})
