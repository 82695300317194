import { CmsImage } from 'src/types'
import {
    StyledPageTitle,
    StyledPageTitleContent,
    StyledWrapper,
} from './PageTitle.style'
import Text from 'src/components/atoms/Text/Text'

type PageTitleProps = {
    title: string
    image: CmsImage
}
const PageTitle: React.FC<PageTitleProps> = ({ title, image }) => {
    return (
        <StyledWrapper>
            <StyledPageTitle css={{ backgroundImage: `url(${image.url})` }}>
                <StyledPageTitleContent>
                    <Text textVariant="heading4Bis">{title}</Text>
                </StyledPageTitleContent>
            </StyledPageTitle>
        </StyledWrapper>
    )
}

export default PageTitle
