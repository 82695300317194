import { styled } from 'src/styles/stitches.config'

export const StyledReviews = styled('div', {})

export const StyledReview = styled('div', {
    '@md': {
        width: 'calc(3.5 * $colWidth)',
    },
})

export const StyledStar = styled('span', {
    fontSize: '1.5rem',
    color: '#e1ddd6',
    variants: {
        fill: {
            true: {
                color: '#5e78a7',
            },
        },
    },
})
