import { styled } from 'src/styles/stitches.config'

export const StyledMainMenu = styled('div', {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 10,
    color: '#fff',
    width: '100%',
    a: {
        position: 'relative',
        display: 'inline-block',
        fontFamily: '$secondary!important',
        fontWeight: '$bold',
        textTransform: 'uppercase',
        fontSize: '1.25rem!important',
        letterSpacing: '0.08em',

        '&::before': {
            position: 'absolute',
            content: '',
            bottom: 0,
            left: 0,
            width: 0,
            height: 1,
            backgroundColor: '$white',
            transition: 'width .4s ease',
        },
        '&:hover::before': {
            width: '100%',
        },
    },
})

export const StyledLogo = styled('img', {
    width: 'calc(3 * $colWidth)',
    height: 'auto',
})
