import { forwardRef } from 'react'
import Select, { ActionMeta } from 'react-select'
type SelectProps = {
    styles?: any
    defaultValue?: any
    onChange?: (newValue: any, actionMeta: ActionMeta<any>) => void
}
const AdultsSelect = forwardRef<any, SelectProps>(
    ({ styles, defaultValue, onChange }, ref) => {
        return (
            <Select
                ref={ref}
                isSearchable={false}
                options={[
                    {
                        value: '1',
                        label: '1',
                    },
                    {
                        value: '2',
                        label: '2',
                    },
                ]}
                defaultValue={defaultValue}
                styles={styles}
                onChange={onChange}
            />
        )
    }
)

export default AdultsSelect
