import { motion } from 'framer-motion'

import { StyledLoader } from './Loader.style'

const Loader: React.FC = (props) => {
    return (
        <StyledLoader
            key="loader"
            as={motion.div}
            initial={{ width: '0%' }}
            animate={{ width: '100%' }}
            exit={{ width: '0%' }}
        />
    )
}

export default Loader
