import { styled } from 'src/styles/stitches.config'

export const StyledPostCard = styled('div', {
    paddingBottom: '$xxs',
    '.content': {
        paddingLeft: '$colWidth',
    },
})

export const StyledImageWrapper = styled('div', {
    ratio: {
        w: 3,
        h: 2,
    },
    img: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
    },
    '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: '$black',
        opacity: '0.15',
        zIndex: 2,
        content: '',
    },
})

export const StyledImageTitle = styled('div', {
    position: 'absolute',
    bottom: '$sm',
    left: '$colWidth',
    top: 'auto!important',
    color: '$white',
    height: 'auto!important',
    zIndex: 3,
})

export const StyledContent = styled('div', {
    '@md': {
        width: '60%',
    },
})
