import { useCallback, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Padder from 'src/components/_layout/Padder/Padder'
import Button from 'src/components/atoms/Button/Button'
import Input from 'src/components/atoms/Input/Input'
import Text from 'src/components/atoms/Text/Text'
import useLabel from 'src/hooks/useLabel'
import { getSettings } from 'src/selectors/settings'
import AdultsSelect from '../AdultsSelect/AdultsSelect'
import RoomSelect from '../RoomSelect/RoomSelect'
import './BookingForm.css'
import {
    DateWrapper,
    selectStyles,
    StyledBookingForm,
    SubmitWrapper,
} from './BookingForm.style'
import { useHistory } from 'react-router'

const BookingForm: React.FC<{ column?: boolean }> = ({ column }) => {
    const { booking, rooms } = useSelector(getSettings)
    const label = useLabel()
    const selectRef = useRef<any>()
    const adultsRef = useRef<any>()
    const { push } = useHistory()
    const [checkinDate, setCheckinDate] = useState<Date>(new Date())
    const [checkoutDate, setCheckoutDate] = useState<Date>(new Date())
    const onFormSubmit = useCallback<React.FormEventHandler<HTMLFormElement>>(
        (e) => {
            e.preventDefault()
            const params = {
                'check-in': checkinDate?.toLocaleDateString('en-US'),
                'check-out': checkoutDate?.toLocaleDateString('en-US'),
                room: selectRef.current.getValue()[0].value,
                adults: adultsRef.current.getValue()[0].value,
            }

            const stringParams = Object.keys(params)
                //@ts-ignore
                .map((key) => key + '=' + params[key])
                .join('&')

            push(`${booking.link.url}?${stringParams}`)
        },
        [booking.link.url, checkinDate, checkoutDate, push]
    )

    return (
        <StyledBookingForm onSubmit={onFormSubmit}>
            <FlexWrapper
                as={Padder}
                css={!column ? { height: '100%' } : undefined}
                {...(column ? { top: 'sm' } : {})}
                justify={column ? 'start' : 'center'}
                align={column ? 'start' : 'center'}
                direction={column ? 'column' : 'row'}
            >
                <FlexWrapper
                    as={Padder}
                    className="booking-wrapper"
                    {...(column ? { top: 'sm' } : { left: 'sm' })}
                    justify={column ? 'start' : 'between'}
                    align={column ? 'start' : 'center'}
                    direction={column ? 'column' : 'row'}
                >
                    <DateWrapper css={column ? { width: '100%' } : undefined}>
                        <Input
                            type="date"
                            name="check-in"
                            label={label('Check-in') + ':'}
                            //@ts-ignore
                            value={checkinDate}
                            //@ts-ignore
                            onChange={setCheckinDate}
                            maxDate={
                                booking.maxDate
                                    ? new Date(booking.maxDate)
                                    : undefined
                            }
                        />
                    </DateWrapper>
                    <DateWrapper css={column ? { width: '100%' } : undefined}>
                        <Input
                            type="date"
                            name="check-out"
                            label={label('Check-out') + ':'}
                            //@ts-ignore
                            value={checkoutDate}
                            //@ts-ignore
                            onChange={setCheckoutDate}
                            maxDate={
                                booking.maxDate
                                    ? new Date(booking.maxDate)
                                    : undefined
                            }
                        />
                    </DateWrapper>
                    <FlexWrapper
                        fitContent
                        direction="column"
                        css={column ? { width: '100%' } : undefined}
                    >
                        {label('Select room') + ':'}
                        <RoomSelect ref={selectRef} styles={selectStyles} />
                    </FlexWrapper>
                    <FlexWrapper
                        fitContent
                        direction="column"
                        css={column ? { width: '100%' } : undefined}
                    >
                        {label('Guests')}:
                        <AdultsSelect
                            ref={adultsRef}
                            defaultValue={{
                                value: '1',
                                label: '1',
                            }}
                            styles={selectStyles}
                        />
                    </FlexWrapper>
                </FlexWrapper>
                <SubmitWrapper
                    css={
                        !column
                            ? {
                                  backgroundColor: '$primaryDark',
                                  height: '100%',
                                  borderRadius: '999px',
                                  overflow: 'hidden',
                                  marginLeft: '-50px',
                              }
                            : undefined
                    }
                >
                    <Button variant={column ? 'blueFill' : 'orange'}>
                        <Text
                            textVariant="button"
                            css={{ fontWeight: '$bold' }}
                        >
                            {label('Book now').toUpperCase()}
                        </Text>
                    </Button>
                </SubmitWrapper>
            </FlexWrapper>
        </StyledBookingForm>
    )
}

export default BookingForm
