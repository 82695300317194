import RichText from 'src/components/atoms/RichText/RichText'
import Text from 'src/components/atoms/Text/Text'
import Composer from 'src/components/helpers/PageComposer/PageComposer'
import Revealer from 'src/components/helpers/Revealer/Revealer'
import SideBooking from 'src/components/organisms/SideBooking/SideBooking'
import Container from 'src/components/_layout/Container/Container'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Padder from 'src/components/_layout/Padder/Padder'
import { CmsImage } from 'src/types'
import { StyledBookingFormText, StyledColumn } from './BookingFormText.style'

type BookingFormTextProps = {
    title: string
    subtitle: string
    content: any
}
const BookingFormText: React.FC<BookingFormTextProps> = ({
    title,
    subtitle,
    content,
}) => {
    return (
        <StyledBookingFormText as={Container}>
            <FlexWrapper
                direction={{ '@initial': 'columnReverse', '@md': 'row' }}
                justify={{ '@initial': 'start', '@md': 'between' }}
            >
                <StyledColumn
                    as={Padder}
                    top="sm"
                    css={{
                        paddingRight: 'calc(1.5 * $colWidth)',
                        '@md': {
                            paddingTop: 'calc(3 * $colWidth)',
                        },
                    }}
                >
                    <Padder top="xs">
                        <Revealer>
                            <Text
                                textVariant="heading3"
                                dangerouslySetInnerHTML={{ __html: title }}
                                css={{
                                    color: '$primary',
                                    fontWeight: '$normal',
                                }}
                            />
                        </Revealer>
                        <Revealer>
                            <Text
                                textVariant="base3"
                                dangerouslySetInnerHTML={{ __html: subtitle }}
                            />
                        </Revealer>
                    </Padder>
                    <Padder top="lg">
                        <Composer
                            components={content.map((component: any) => ({
                                ...component,
                                component: component.acf_fc_layout,
                            }))}
                        />
                    </Padder>
                </StyledColumn>
                <SideBooking />
            </FlexWrapper>
        </StyledBookingFormText>
    )
}

export default BookingFormText
