import { styled } from 'src/styles/stitches.config'

export const StyledTitle = styled('div', {
    textAlign: 'center',
})

export const StyledSubtitle = styled('div', {
    width: '80%',

    margin: 'auto',
    '@md': {
        maxWidth: 'calc($colWidth * 6)',
    },
})

export const StyledSlider = styled('div', {
    position: 'relative',
    paddingBottom: '$sm',

    '@lg': {
        paddingLeft: 'calc(3 * $colWidth)',
        marginTop: 'calc($xl * -1)!important',
    },
    variants: {
        topNormal: {
            true: {
                marginTop: '0!important',
            },
        },
    },
})

export const StyledNavigation = styled('div', {
    fontSize: '2rem',

    '@md': {
        position: 'absolute',
        bottom: 0,
        right: 'calc(2 * $colWidth)',
        zIndex: 1,
    },

    '@mdDown': {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '$md',
    },
})
