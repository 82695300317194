import { styled } from 'src/styles/stitches.config'

export const Social = styled('button', {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '1.25rem',
    height: '1.25rem',
    backgroundColor: '$primaryDark',
    color: '$white',
    borderRadius: '50%',
    fontSize: '0.85rem',
    a: {
        lineHeight: 0,
    },
    '@mdDown': {
        width: '2.5rem',
        height: '2.5rem',
        fontSize: '1.25rem',
    },
})
