import Button from 'src/components/atoms/Button/Button'
import {
    StyledDatePicker,
    StyledInput,
} from 'src/components/atoms/Input/Input.style'
import {
    StyledBookingForm,
    SubmitWrapper,
} from 'src/components/molecules/BookingForm/BookingForm.style'
import { styled } from 'src/styles/stitches.config'

export const StyledSideBooking = styled('div', {
    height: 'auto',
    color: '$white',
    '.booking-wrapper': {
        width: '100%',
    },
    '@md': {
        width: 'calc(6 * $colWidth)',
        justifySelf: 'flex-end',
        marginTop: '0',
    },

    '@mdDown': {
        marginBottom: '$md',
    },

    [`${StyledInput} input`]: {
        width: '100%',
    },

    [`${Button}`]: {
        transform: 'translateY(50%)',
        minHeight: '80px',
    },

    '--select-background': '#d78155',
    '--select-singleValue': 'var(--colors-white)',
    svg: {
        stroke: '$white!important',
    },
    '.react-date-picker': {
        backgroundColor: '#d78155!important',
        paddingLeft: '$xxs',
        color: '$white',
    },
    [`${StyledInput} input`]: {
        color: '$white',
        borderColor: '$white',
        backgroundColor: '#d78155!important',
        '&:placeholder': {
            color: 'white',
        },
        '&::-webkit-calendar-picker-indicator': {
            filter: 'invert(1)',
        },
    },
    [`${StyledDatePicker}`]: {
        paddingLeft: 0,
        marginTop: '$xxs',
    },
    [`${SubmitWrapper}`]: {
        '@md': {
            width: 'calc(4 * $colWidth)',
        },
    },
})

export const StyledSideBookingContent = styled('div', {
    padding: '$colWidth $colWidth 0',
    width: '100%',
    background: '$primary',
})
