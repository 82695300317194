import { Link } from 'react-router-dom'
import Container from 'src/components/_layout/Container/Container'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Padder from 'src/components/_layout/Padder/Padder'
import Button from 'src/components/atoms/Button/Button'
import RichText from 'src/components/atoms/RichText/RichText'
import Text from 'src/components/atoms/Text/Text'
import Revealer from 'src/components/helpers/Revealer/Revealer'
import { CmsLink } from 'src/types'
import { StyledItem } from './FeaturedPages.style'

type FeaturedPageItem = {
    title: string
    description: string
    subtitle: string
    link: CmsLink
}

type FeaturedPageProps = {
    items: Array<FeaturedPageItem>
}

const FeaturedPages: React.FC<FeaturedPageProps> = ({ items }) => {
    return (
        <Container>
            <FlexWrapper
                as={Padder}
                justify={{ '@initial': 'start', '@md': 'around' }}
                direction={{ '@initial': 'column', '@md': 'row' }}
                left={{ '@md': 'doubleColWidth' }}
                top={{ '@mdDown': 'lg' }}
            >
                {items.map(({ title, subtitle, description, link }, index) => (
                    <StyledItem key={index}>
                        <Revealer>
                            <Padder top="md">
                                <div>
                                    <Text
                                        as="h4"
                                        textVariant="heading3"
                                        color="primaryDark"
                                        dangerouslySetInnerHTML={{
                                            __html: title,
                                        }}
                                    />
                                    <Text
                                        textVariant="heading3"
                                        color="primaryDark"
                                        css={{ fontWeight: '$normal' }}
                                        dangerouslySetInnerHTML={{
                                            __html: subtitle,
                                        }}
                                    />
                                </div>
                                <RichText
                                    textVariant="base2"
                                    htmlString={description}
                                />
                                <Link to={link.url}>
                                    <Button variant="light">
                                        {link.title}
                                    </Button>
                                </Link>
                            </Padder>
                        </Revealer>
                    </StyledItem>
                ))}
            </FlexWrapper>
        </Container>
    )
}

export default FeaturedPages
