import { useResponsive } from 'ahooks'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Button from 'src/components/atoms/Button/Button'
import Text from 'src/components/atoms/Text/Text'
import BookingForm from 'src/components/molecules/BookingForm/BookingForm'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import useLabel from 'src/hooks/useLabel'
import { getSettings } from 'src/selectors/settings'
import { StyledHorizontalBooking } from './HorizontalBooking.style'

const HorizontalBooking: React.FC = (props) => {
    const responsiveInfo = useResponsive()
    const { booking } = useSelector(getSettings)
    console.log(booking)
    const label = useLabel()
    return (
        <StyledHorizontalBooking>
            {responsiveInfo.md ? (
                <BookingForm />
            ) : (
                <FlexWrapper justify="center" css={{ padding: '$xs' }}>
                    <a href={booking.pureLink.url} style={{ width: '80%' }}>
                        <Button variant="orange" css={{ width: '100%' }}>
                            <Text
                                textVariant="base2"
                                css={{ fontWeight: '$medium' }}
                            >
                                {label('Book now').toUpperCase()}
                            </Text>
                        </Button>
                    </a>
                </FlexWrapper>
            )}
        </StyledHorizontalBooking>
    )
}

export default HorizontalBooking
