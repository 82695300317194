import { useResponsive } from 'ahooks'
import Image from 'src/components/atoms/Image/Image'
import BookingForm from 'src/components/molecules/BookingForm/BookingForm'
import { CmsImage } from 'src/types'
import {
    StyledSideBooking,
    StyledSideBookingContent,
} from './SideBooking.style'

const SideBooking: React.FC<{ image?: CmsImage }> = ({ image }) => {
    const { md } = useResponsive()
    return (
        <StyledSideBooking>
            {!!image && md && <Image src={image.url} alt={image.alt} />}
            <StyledSideBookingContent>
                <BookingForm column />
            </StyledSideBookingContent>
        </StyledSideBooking>
    )
}

export default SideBooking
