import { useResponsive } from 'ahooks'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import Container from 'src/components/_layout/Container/Container'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Padder from 'src/components/_layout/Padder/Padder'
import Button from 'src/components/atoms/Button/Button'
import Logo from 'src/components/atoms/Logo/Logo'
import Text from 'src/components/atoms/Text/Text'
import { getLayerById } from 'src/selectors/layers'
import { toggleLayer } from 'src/state/layers'
import { RootState } from 'src/store'
import { StyledHeader } from './Header.style'
import Portal from 'src/components/helpers/Portal/Portal'
const Header: React.FC = (props) => {
    const dispatch = useDispatch()
    const [canPortal, setCanPortal] = useState(false)
    const menuLayer = useSelector((state: RootState) =>
        getLayerById(state, 'mainMenu')
    )

    const toggleMenu = useCallback(() => {
        if (menuLayer) {
            dispatch(
                toggleLayer({
                    layerId: menuLayer.id,
                })
            )
        }
    }, [dispatch, menuLayer])

    const Wrapper = useMemo(() => {
        return canPortal ? Portal : Fragment
    }, [canPortal])

    useEffect(() => {
        if (menuLayer?.isActive) {
            setCanPortal(true)
        } else {
            setTimeout(() => {
                setCanPortal(false)
            }, 1000)
        }
    }, [menuLayer?.isActive])

    const Component = () => {
        return (
            <StyledHeader id="header" className="light">
                <Container as={FlexWrapper} justify="between" align="center">
                    <Logo />
                    <FlexWrapper as={Padder} left="colWidth" align="center">
                        <div>
                            <Button onClick={toggleMenu}>
                                <Text
                                    textVariant="base2"
                                    css={{
                                        fontWeight: '$bold',
                                        letterSpacing: '0.05rem',
                                    }}
                                >
                                    MENU
                                </Text>
                            </Button>
                        </div>
                    </FlexWrapper>
                </Container>
            </StyledHeader>
        )
    }

    return <Wrapper domQuery={'#root'}>{Component()}</Wrapper>
}

export default Header
