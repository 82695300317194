import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type LayoutType = {
	verticalLogoVisible: boolean;
};

const initialState: LayoutType = {
	verticalLogoVisible: false,
};

const layoutSlice = createSlice({
	name: 'layout',
	initialState,
	reducers: {
		displayVerticalLogo: (state, action: PayloadAction<boolean>) => ({
			...state,
			verticalLogoVisible: action.payload,
		}),
	},
});

export const { displayVerticalLogo } = layoutSlice.actions;
export default layoutSlice.reducer;
