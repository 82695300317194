import { useSelector } from 'react-redux'
import Composer from 'src/components/helpers/PageComposer/PageComposer'
import { getSettingsComponents } from 'src/selectors/settings'

const GlobalComponent: React.FC<{ globalComponent: any }> = ({
    globalComponent,
}) => {
    const components = useSelector(getSettingsComponents)
    return <Composer components={[components[globalComponent]]} />
}

export default GlobalComponent
