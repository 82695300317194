import RichText from 'src/components/atoms/RichText/RichText'
import Text from 'src/components/atoms/Text/Text'
import PostCard, {
    PostCardProps,
} from 'src/components/molecules/PostCard/PostCard'
import Padder from 'src/components/_layout/Padder/Padder'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperInstance from 'swiper'
import {
    StyledNavigation,
    StyledSlider,
    StyledSubtitle,
    StyledTitle,
} from './RoomSlider.style'
import {
    HiOutlineArrowNarrowLeft,
    HiOutlineArrowNarrowRight,
} from 'react-icons/hi'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import { useMemo, useRef } from 'react'
import Revealer from 'src/components/helpers/Revealer/Revealer'
import { useLocation } from 'react-router'
import { breakpoints } from 'src/styles/stitches.config'
import { useResponsive } from 'ahooks'
import { CmsImage } from 'src/types'
import Banner from '../Banner/Banner'
import Container from 'src/components/_layout/Container/Container'
import useLabel from 'src/hooks/useLabel'
type RoomSliderProps = {
    rooms: Array<PostCardProps>
    title: string
    subtitle?: string
    image?: CmsImage
    disableBanner?: boolean
}
const RoomSlider: React.FC<RoomSliderProps> = ({
    title,
    subtitle,
    image,
    rooms,
    disableBanner,
}) => {
    const swiperRef = useRef<SwiperInstance>()
    const location = useLocation()
    const label = useLabel()
    const responsiveInfo = useResponsive()
    const Navigation = useMemo(
        () => (
            <StyledNavigation>
                <FlexWrapper css={{ gap: '1rem' }} align="center">
                    <button
                        type="button"
                        onClick={() => swiperRef.current?.slidePrev()}
                        style={{ height: 'auto', lineHeight: 0 }}
                    >
                        <HiOutlineArrowNarrowLeft />
                    </button>
                    <button
                        type="button"
                        onClick={() => swiperRef.current?.slideNext()}
                        style={{ height: 'auto', lineHeight: 0 }}
                    >
                        <HiOutlineArrowNarrowRight />
                    </button>
                </FlexWrapper>
            </StyledNavigation>
        ),
        []
    )

    return (
        <>
            {!!image && !!title && !!subtitle && (
                <Banner title={title} subtitle={subtitle} image={image} />
            )}

            <StyledSlider topNormal={!image || !title || !subtitle}>
                {!!title && !subtitle && (
                    <Text
                        as="h4"
                        textVariant="heading3"
                        color="primary"
                        dangerouslySetInnerHTML={{
                            __html: title,
                        }}
                        css={{
                            marginTop: '$lg',
                            marginBottom: '$lg',
                            '@mdDown': {
                                paddingLeft: '2rem',
                            },
                        }}
                    />
                )}
                {!responsiveInfo.md && Navigation}
                <Swiper
                    slidesPerView={1}
                    spaceBetween={0}
                    onSwiper={(swiper) => {
                        swiperRef.current = swiper
                    }}
                    breakpoints={{
                        [breakpoints.md]: {
                            slidesPerView: 2,
                        },
                    }}
                >
                    {rooms
                        .filter((room) => room.link.url !== location.pathname)
                        .map((room, index) => (
                            <SwiperSlide key={index}>
                                <PostCard {...room} forceReveal minimal />
                            </SwiperSlide>
                        ))}
                </Swiper>
                {responsiveInfo.md && Navigation}
            </StyledSlider>
        </>
    )
}

export default RoomSlider
