import { useSelector } from 'react-redux'
import { getLabels } from 'src/selectors/settings'

const useLabel = (): ((key: string) => string) => {
    const labels = useSelector(getLabels)

    if (!labels || Object.keys(labels).length === 0)
        return () => 'label cannot be loaded'

    const label = (key: string): string =>
        typeof labels[key] === 'undefined' ? key : labels[key]

    return label
}
export default useLabel
