export const getGlobalComponent = (
    components: Array<Record<string, any>>,
    name: string
) => {
    if (!components) {
        return undefined
    }
    const key = Object.keys(components).find(
        //@ts-ignore
        (key: string) => components[key].component === name
    )
    if (!key) {
        return undefined
    }
    //@ts-ignore
    return components[key]
}

export const chunk = <T>(arr: Array<T>, size: number) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
        arr.slice(i * size, i * size + size)
    )
