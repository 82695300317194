import Container from 'src/components/_layout/Container/Container'
import { styled } from 'src/styles/stitches.config'

export const StyledBanner = styled('div', {
    position: 'relative',
    overflow: 'hidden',
    img: {
        position: 'relative',
        '@mdDown': {
            width: '100%',
            height: 450,
            objectFit: 'cover',
            objectPosition: 'center',
        },
    },

    '&::before': {
        content: '',
        position: 'absolute',
        width: '$colWidth',
        height: '$colWidth',
        top: 0,
        left: '50%',
        zIndex: 2,
        maskImage: `url(https://www.aurosavacanze.it/wp-content/themes/progetty/static/media/shape.33954c0e.svg)`,
        backgroundColor: '$white',
        transform: 'translate(-50%, -50%)',
    },
})

export const StyledContent = styled(Container, {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: '$white',
    textAlign: 'center',
})
