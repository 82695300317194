import { styled } from 'src/styles/stitches.config'

export const StyledPopupWrapper = styled('div', {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1200,
    width: '100vw',
    height: '100vh',
    maxHeight: '-webkit-fill-available',
    backgroundColor: 'rgba(255,255,255,0.8)',
})

export const StyledPopup = styled('div', {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    height: '70vh',
    width: 'auto',
    margin: 'auto',
    backgroundColor: 'transparent',
    padding: 0,
    color: '#fff',
    zIndex: 2,
    borderRadius: 15,
    overflow: 'hidden',

    img: { position: 'relative', height: '70vh', width: 'auto', zIndex: 2 },

    '@mdDown': {
        padding: '0',
        width: '90%',
        height: '85vh',
        backgroundColor: '#1B2A47',
        img: {
            width: '100%',
            height: '100%',
            objectFit: 'contain',
        },
    },
})

export const StyledCloser = styled('button', {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 5,
    top: 5,
    width: '2.5rem',
    height: '2.5rem',
    cursor: 'pointer',
    backgroundColor: '#000',
    borderRadius: '50%',
    color: '#fff',
    fontSize: '25px',
    zIndex: 10000,
})
