import { Link } from 'react-router-dom'
import { CmsLink } from 'src/types'
import Text from '../Text/Text'
import { StyledLinkButton } from './LinkButton.style'

const LinkButton: React.FC<CmsLink & { textVariant?: string }> = ({
    url,
    title,
    target,
    textVariant = 'base',
}) => {
    const targetValue = url.startsWith('http') ? '_blank' : target
    const isSpecialLink = url.startsWith('mailto') || url.startsWith('tel')
    return !!url ? (
        <StyledLinkButton
            as={targetValue === '_blank' || isSpecialLink ? 'a' : Link}
            {...(targetValue === '_blank' || isSpecialLink
                ? { href: url }
                : { to: url })}
            target={targetValue}
        >
            <Text
                //@ts-ignore
                textVariant={textVariant}
                textTransform="uppercase"
                css={{ letterSpacing: '1px', fontWeight: '$medium' }}
            >
                {title}
            </Text>
        </StyledLinkButton>
    ) : null
}

export default LinkButton
