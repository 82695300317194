import { configureStore } from '@reduxjs/toolkit';

import layersSlice from 'src/state/layers';
import layoutSlice from 'src/state/layout';
import settingsSlice from 'src/state/settings';

export const store = configureStore({
	reducer: {
		settings: settingsSlice,
		layers: layersSlice,
		layout: layoutSlice
	},
	devTools: process.env.NODE_ENV !== 'production'
});
    

export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch