import { forwardRef, useCallback } from 'react'
import { useSelector } from 'react-redux'
import ReactSelect from 'react-select'
import useLabel from 'src/hooks/useLabel'
import useQueryParams from 'src/hooks/useQueryParams'
import { getSettings } from 'src/selectors/settings'
type SelectProps = {
    styles?: any
    onChange?: (type: string) => void
}
const RoomSelect = forwardRef<any, SelectProps>(({ styles, onChange }, ref) => {
    const { rooms } = useSelector(getSettings)
    const query = useQueryParams()

    const handleChange = useCallback(
        (roomName) => {
            const selectedRoom = rooms.find(({ name }) => name === roomName)
            if (typeof onChange === 'function' && !!selectedRoom) {
                onChange(selectedRoom.type)
            }
        },
        [onChange, rooms]
    )

    return (
        rooms && (
            <ReactSelect
                ref={ref}
                isSearchable={false}
                options={rooms.map(({ name, type }) => ({
                    label: name,
                    value: name,
                }))}
                defaultValue={{
                    value: query.get('room') || (rooms[0].name ?? ''),
                    label: query.get('room') || (rooms[0].name ?? ''),
                }}
                styles={styles}
                onChange={(val) => handleChange(val?.value)}
            />
        )
    )
})

export default RoomSelect
