import ImageReveal from 'src/components/atoms/ImageReveal/ImageReveal'
import { CmsImage } from 'src/types'
import { StyledBanner, StyledContent } from './Banner.style'
import Text from 'src/components/atoms/Text/Text'
import Padder from 'src/components/_layout/Padder/Padder'

const Banner: React.FC<{
    image: CmsImage
    title: string
    subtitle: string
}> = ({ image: { url, alt }, title, subtitle }) => {
    return (
        <StyledBanner>
            <ImageReveal src={url} alt={alt} />
            <StyledContent>
                <Padder top="md">
                    <Text
                        textVariant="heading3Bis"
                        dangerouslySetInnerHTML={{ __html: title }}
                    />
                    <Text
                        textVariant="base2"
                        dangerouslySetInnerHTML={{ __html: subtitle }}
                    />
                </Padder>
            </StyledContent>
        </StyledBanner>
    )
}

export default Banner
