import { useSelector } from 'react-redux'
import { StyledLogo, StyledMainMenu } from './MainMenu.style'
import { getSettings } from 'src/selectors/settings'
import { useMemo } from 'react'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Padder from 'src/components/_layout/Padder/Padder'
import { getLinkComponent } from 'src/utils/menu'
import Container from 'src/components/_layout/Container/Container'

const MainMenu: React.FC = (props) => {
    const { menu, logo } = useSelector(getSettings)
    const [firstMenu, secondMenu] = useMemo(
        () => menu.filter((m) => ['Desktop 1', 'Desktop 2'].includes(m.name)),
        [menu]
    )
    return (
        !!firstMenu?.items &&
        !!secondMenu?.items && (
            <StyledMainMenu>
                <Container>
                    <FlexWrapper align="center" justify="around">
                        <FlexWrapper as={Padder} left={{ '@md': 'sm' }}>
                            {firstMenu.items.map(getLinkComponent)}
                        </FlexWrapper>
                        <div>
                            <StyledLogo src={logo} />
                        </div>
                        <FlexWrapper as={Padder} left={{ '@md': 'sm' }}>
                            {secondMenu.items.map(getLinkComponent)}
                        </FlexWrapper>
                    </FlexWrapper>
                </Container>
            </StyledMainMenu>
        )
    )
}

export default MainMenu
