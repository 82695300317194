import { styled } from 'src/styles/stitches.config'

const Button = styled('button', {
    display: 'inline-block',
    border: 'solid 1px $black',
    width: 'auto',
    padding: '$xxs $xs',
    fontFamily: '$button',
    fontWeight: '$bold',
    lineHeight: '1.2',
    fontSize: '1.125rem',
    letterSpacing: '0.05em',
    textTransform: 'uppercase',
    borderRadius: '999px',
    variants: {
        variant: {
            white: {
                border: 'solid 1px $white',
                color: '$white',
            },

            whiteFill: {
                color: '$black',
                backgroundColor: '$white',
                border: 'solid 1px $white',
            },
            blue: {
                border: 'solid 1px $primaryDark',
                color: '$primaryDark',
            },
            blueFill: {
                border: 'solid 1px $primaryDark',
                color: '$white',
                backgroundColor: '$primaryDark',
            },

            orange: {
                color: '$white',
                backgroundColor: '$primaryDark',
                border: 'solid 1px $primaryDark',
            },

            light: {
                color: '$primaryDark',
                backgroundColor: '$whiteSmoke',
                border: 'solid 1px $whiteSmoke',
            },
        },
        round: {
            true: {
                borderRadius: '15px',
                overflow: 'hidden',
                fontSize: '1.5rem',
                fontFamily: '$secondary',
            },
        },
    },
})

export default Button
