import { styleConstants, styled } from 'src/styles/stitches.config'

export default styled('div', {
    position: 'relative',
    width: '100%',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    boxSizing: 'border-box',

    '@sm': {
        paddingLeft: `${styleConstants.colWidth * 3}vw`,
        paddingRight: `${styleConstants.colWidth * 3}vw`,
    },
})
