import { createSelector } from 'reselect'
import { RootState } from 'src/store'

export const getSettings = (state: RootState) => state.settings

export const getSettingsComponents = createSelector(
    getSettings,
    (settings) => settings.components
)

export const getLabels = createSelector(
    getSettings,
    (settings) => settings.labels
)

export const getFooter = createSelector(
    getSettings,
    (settings) => settings.footer
)
