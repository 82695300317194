import Button from 'src/components/atoms/Button/Button'
import {
    StyledDatePicker,
    StyledInput,
} from 'src/components/atoms/Input/Input.style'
import { styled } from 'src/styles/stitches.config'

export const StyledHorizontalBooking = styled('div', {
    position: 'relative',
    backgroundColor: 'transparent',
    padding: '0 calc(2 * $colWidth)',
    marginTop: '0!important',
    color: '$white',
    zIndex: '10',
    transform: 'translateY(-50%)',
    '--select-background': '#d78155',
    '--select-singleValue': 'var(--colors-white)',

    '@lg': {
        height: '15vh',
    },

    [`${StyledInput} input`]: {
        color: '$white',
        borderColor: '$white',
        backgroundColor: '#d78155!important',
    },
    svg: {
        stroke: '$white!important',
    },
    '.react-date-picker': {
        marginTop: '$xxs',

        backgroundColor: '#d78155!important',
    },
    [`${StyledDatePicker}`]: {
        paddingLeft: 0,
    },

    '.booking-wrapper': {
        height: '100%',
        width: '70%',
        background: '$primary',
        paddingLeft: '$colWidth',
        paddingRight: '$colWidth',
        borderRadius: '999px',
    },
})

//Erase:
export const DateWrapper = styled('div', {
    width: 'calc(4 * $colWidth)',
})

export const SubmitWrapper = styled('div', {
    width: 'calc(5 * $colWidth)',
    height: '100%',
    [`${Button}`]: {
        width: '100%',
        height: '100%',
    },
})

export const selectStyles = {
    control: (provided: any) => ({
        ...provided,
        backgroundColor: '#d78155',
        border: 'none',
        boxShadow: 'none!important',
        height: '50px',
        marginTop: 'var(--space-xs)',
        paddingLeft: '0.5rem',
        paddingRight: '0.5rem',
    }),
    singleValue: (provided: any) => ({
        ...provided,
        color: 'var(--colors-white)',
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        color: '#000',
        backgroundColor: state.isSelected
            ? '#ddd'
            : state.isFocused
            ? '#eee'
            : '#fff',
    }),
}
