import type * as Stitches from '@stitches/react'
import { styled, theme } from 'src/styles/stitches.config'

const Text = styled('div', {
    fontFamily: '$primary',
    fontSize: '1rem',
    fontWeight: '$normal',
    variants: {
        color: Object.fromEntries(
            Object.keys(theme.colors).map((colorKey) => [
                colorKey,
                { color: `$${colorKey}` },
            ])
        ),
        align: {
            center: { textAlign: 'center' },
            right: { textAlign: 'right' },
            left: { textAlign: 'left' },
            justify: { textAlign: 'justify' },
        },
        textTransform: {
            uppercase: {
                textTransform: 'uppercase',
            },
            lowercase: {
                textTransform: 'lowercase',
            },
        },
        textVariant: {
            heading1: {
                fontFamily: '$secondary',
                fontWeight: '$medium',
                lineHeight: 1,
                fontSize: '6.25rem',
                '@mdDown': {
                    fontSize: '3rem',
                },
            },
            heading2: {
                fontFamily: '$secondary',
                fontWeight: '$medium',
                lineHeight: 1,
                fontSize: '5rem',
            },
            heading3: {
                fontFamily: '$secondary',
                fontWeight: '$bold',
                lineHeight: 1,
                fontSize: '3rem',
                '@mdDown': {
                    fontSize: '2.35rem',
                },
            },

            heading3Bis: {
                fontFamily: '$secondary',
                fontWeight: '$bold',
                lineHeight: 1,
                fontSize: '3.5rem',
                '@mdDown': {
                    fontSize: '2rem',
                },
            },
            heading4: {
                fontFamily: '$secondary',
                fontWeight: '$medium',
                lineHeight: 1,
                fontSize: '2rem',
            },
            heading4Bis: {
                fontFamily: '$secondary',
                fontWeight: '$bold',
                lineHeight: 1,
                fontSize: '2.5625rem',
                '@mdDown': {
                    fontSize: '1.9rem',
                    letterSpacing: '0.02em',
                },
            },
            heading5: {
                fontFamily: '$secondary',
                lineHeight: 1,
                fontSize: '1.83rem',
                fontWeight: '$medium',
            },
            base: {
                lineHeight: '1.2',
                fontSize: '1rem',
            },
            base2: {
                lineHeight: '1.2',
                fontSize: '1.125rem',
            },
            button: {
                fontFamily: '$button',
                fontWeight: '$bold',
                lineHeight: '1.2',
                fontSize: '1.125rem',
            },
            base3: {
                lineHeight: '1.2',
                fontSize: '2rem',
            },
            base3Bis: {
                fontFamily: '$secondary',
                lineHeight: '1.2',
                fontSize: '1.8rem',
            },
            small: {
                fontSize: '0.85rem',
                lineHeight: '1.3',
            },
        },
    },
})

export type TextVariants = Stitches.VariantProps<typeof Text>

export default Text
