import { useSelector } from 'react-redux'

import Text from 'src/components/atoms/Text'
import { getLabels } from 'src/selectors/settings'

type FieldErrorProps = {
    errorType: string
}

const FieldError: React.FC<FieldErrorProps> = ({ errorType }) => {
    const labels = useSelector(getLabels)
    return (
        <Text textVariant="small" style={{ color: '#900', marginTop: '15px' }}>
            {labels[`error.${errorType}`]}
        </Text>
    )
}

export default FieldError
