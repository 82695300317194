import LinkButton from 'src/components/atoms/LinkButton/LinkButton'
import { CmsLink } from 'src/types'
import { StyledContentLink } from './ContentLink.style'

const ContentLink: React.FC<{ link: CmsLink }> = ({ link }) => {
    return (
        <div>
            <LinkButton {...link} textVariant="base" />
        </div>
    )
}

export default ContentLink
