import Button from 'src/components/atoms/Button/Button'
import { styled } from 'src/styles/stitches.config'

export const StyledBookingForm = styled('form', {
    height: '100%',
    '@mdDown': {
        marginBottom: '$md',
    },
})

export const DateWrapper = styled('div', {
    width: 'calc(4 * $colWidth)',
})

export const SubmitWrapper = styled('div', {
    height: '100%',
    width: '80%',
    '@mdDown': {
        margin: 'auto',
    },
    '@md': {
        width: 'calc(5 * $colWidth)',
    },
    [`${Button}`]: {
        width: '100%',
        height: '100%',
    },
})

export const selectStyles = {
    control: (provided: any) => ({
        ...provided,
        backgroundColor: 'var(--select-background)',
        border: 'none',
        boxShadow: 'none!important',
        height: '50px',
        marginTop: 'var(--space-xs)',
        paddingLeft: '0.5rem',
        paddingRight: '0.5rem',
    }),
    singleValue: (provided: any) => ({
        ...provided,
        color: 'var(--select-singleValue)',
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    indicatorContainer: () => ({
        color: '#fff',
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        color: '#000',
        backgroundColor: state.isSelected
            ? '#ddd'
            : state.isFocused
            ? '#eee'
            : '#fff',
    }),
}
