import { PostCardProps } from 'src/components/molecules/PostCard/PostCard'
import RoomSlider from '../RoomSlider/RoomSlider'
import useLabel from 'src/hooks/useLabel'

const RoomsRelated: React.FC<{ roomsRelated: Array<PostCardProps> }> = ({
    roomsRelated,
}) => {
    const label = useLabel()
    return <RoomSlider rooms={roomsRelated} title={label('more_rooms')} />
}

export default RoomsRelated
