import { styled } from 'src/styles/stitches.config'

const Textarea = styled('textarea', {
    display: 'block',
    width: '100%',
    height: '200px',
    backgroundColor: 'transparent',
    padding: '10px',
    border: 'none',
    color: '$black',
    fontSize: '1.15rem',
    fontFamily: '$primary',
})

export default Textarea
