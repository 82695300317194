import { styled } from 'src/styles/stitches.config'

export const StyledLogo = styled('img', {
    position: 'relative',
    maxWidth: '100%',
})

export const StyledLink = styled('a', {
    position: 'relative',
    width: '35vw',
    height: '35vw',
    maskPosition: 'center',
    maskSize: 'contain',
    maskRepeat: 'no-repeat',
    // backgroundColor: '$primaryDark',
    // '.light &': {
    //     backgroundColor: '$white',
    // },
})
