import Text from '../Text/Text'
import { StyledRichText } from './RichText.style'

type RichTextProps = {
    htmlString: string
    textVariant?: string
    fontWeight?: string
    align?: string
    className?: string
}
const RichText: React.FC<RichTextProps> = ({
    htmlString,
    textVariant = 'base',
    fontWeight = '$normal',
    align = 'left',
    className,
}) => {
    return (
        //@ts-ignore
        <Text
            textVariant={textVariant}
            css={{ fontWeight }}
            align={align}
            className={className}
        >
            <StyledRichText dangerouslySetInnerHTML={{ __html: htmlString }} />
        </Text>
    )
}

export default RichText
