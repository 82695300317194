import { styled } from 'src/styles/stitches.config'

export const StyledRoomsListing = styled('div', {})

export const StyledItem = styled('div', {})

export const StyledItemContent = styled('div', {
    '@md': {
        width: 'calc(6 * $colWidth)',
        '.line': {
            position: 'relative',
            paddingBottom: '2rem',
        },
    },

    '.line::after': {
        position: 'absolute',
        bottom: '0',
        left: 0,
        width: '50vw',
        height: 1,
        backgroundColor: '$primary',
        content: '',
    },
})

export const StyledItemImage = styled('div', {
    '@md': {
        width: 'calc(10 * $colWidth)',
    },
})
