import Image from 'src/components/atoms/Image/Image'
import LinkButton from 'src/components/atoms/LinkButton/LinkButton'
import RichText from 'src/components/atoms/RichText/RichText'
import Text from 'src/components/atoms/Text/Text'
import Container from 'src/components/_layout/Container/Container'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Padder from 'src/components/_layout/Padder/Padder'
import useLabel from 'src/hooks/useLabel'
import { CmsImage } from 'src/types'
import { StyledPost } from './PostListing.style'

type PostListingProps = {
    category: Array<{
        image: CmsImage
        title: string
        excerpt: string
        link: string
    }>
}

const PostListing: React.FC<PostListingProps> = ({ category: items }) => {
    const label = useLabel()
    return (
        <Container>
            <FlexWrapper
                direction={{ '@initial': 'column', '@md': 'row' }}
                css={{ flexWrap: 'wrap', gap: '$colWidth' }}
            >
                {items.map(({ image, title, excerpt, link }, index) => (
                    <StyledPost key={index}>
                        <Padder top="sm" css={{ textAlign: 'center' }}>
                            <Image src={image.url} alt={image.alt} />
                            <Text textVariant="heading4">{title}</Text>
                            <RichText htmlString={excerpt} align="center" />
                            <LinkButton
                                url={link}
                                title={label('read_more')}
                                textVariant="base"
                            />
                        </Padder>
                    </StyledPost>
                ))}
            </FlexWrapper>
        </Container>
    )
}

export default PostListing
