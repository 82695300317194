import { styled } from 'src/styles/stitches.config'

const RadioCheckWrapper = styled('label', {
    position: 'relative',
    a: {
        textDecoration: 'underline',
    },
    'input[type="radio"], input[type="checkbox"]': {
        position: 'absolute',
        visibility: 'hidden',
        opacity: 0,
        '+span': {
            paddingLeft: '30px',
            boxSizing: 'border-box',
            cursor: 'pointer',
        },
        '+ span::before': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '16px',
            height: '16px',
            border: 'solid 1px #aaa',
            backgroundColor: '#fff',
            content: '',
            boxSizing: 'border-box',
            borderRadius: '50%',
        },
        '&:checked + span::after': {
            position: 'absolute',
            top: '4px',
            left: '4px',
            width: '8px',
            height: '8px',
            backgroundColor: '#000',
            content: '',
            boxSizing: 'border-box',
            borderRadius: '50%',
        },
    },
})

export default RadioCheckWrapper
