import React, { useMemo } from 'react'
import ReactDOM from 'react-dom'

//@ts-ignore
export default function Portal({ domQuery, children }) {
    const [hasMounted, setHasMounted] = React.useState(false)
    const domEl = useMemo(
        () =>
            hasMounted && domQuery
                ? document.querySelector(`${domQuery}`)
                : null,
        [domQuery, hasMounted]
    )

    React.useEffect(() => {
        setHasMounted(true)
    }, [])

    if (!hasMounted) {
        return null
    }
    return domEl ? ReactDOM.createPortal(children, domEl) : null
}
