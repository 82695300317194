import { createContext, MutableRefObject, useRef } from 'react';

export interface ScrollbarType {
	scrollbar: any;
}

const initialContext = {
	scrollbar: null,
};

export const ScrollbarContext = createContext<ScrollbarType>(initialContext);

const ScrollbarProvider: React.FC = ({ children }) => {
	const scrollbar = useRef() as MutableRefObject<HTMLDivElement>;
	return (
		<ScrollbarContext.Provider value={{ scrollbar: scrollbar }}>
			{children}
		</ScrollbarContext.Provider>
	);
};

export default ScrollbarProvider;
