import { Link } from 'react-router-dom'
import Image from 'src/components/atoms/Image/Image'
import ImageReveal from 'src/components/atoms/ImageReveal/ImageReveal'
import LinkButton from 'src/components/atoms/LinkButton/LinkButton'
import RichText from 'src/components/atoms/RichText/RichText'
import Text from 'src/components/atoms/Text/Text'
import Revealer from 'src/components/helpers/Revealer/Revealer'
import Padder from 'src/components/_layout/Padder/Padder'
import { CmsImage, CmsLink } from 'src/types'
import {
    StyledContent,
    StyledImageTitle,
    StyledImageWrapper,
    StyledPostCard,
} from './PostCard.style'
import Button from 'src/components/atoms/Button/Button'

export type PostCardProps = {
    image: CmsImage
    title: string
    excerpt: string
    link: CmsLink
    forceReveal?: boolean
    minimal?: boolean
}

const PostCard: React.FC<PostCardProps> = ({
    image,
    title,
    excerpt,
    link,
    forceReveal,
    minimal,
}) => {
    return (
        <StyledPostCard as={Padder} top="sm">
            <Link to={link.url} style={{ width: '100%' }}>
                <StyledImageWrapper>
                    {forceReveal ? (
                        <Image src={image.url} alt={image.alt} />
                    ) : (
                        <ImageReveal src={image.url} alt={image.alt} />
                    )}
                    {minimal && (
                        <StyledImageTitle>
                            <Text textVariant="heading3">{title}</Text>
                        </StyledImageTitle>
                    )}
                </StyledImageWrapper>
            </Link>
            <Revealer forceReveal={forceReveal}>
                <Padder top="sm" className="content">
                    <Padder top="sm">
                        {!minimal && (
                            <Text as="h4" textVariant="heading4">
                                {title}
                            </Text>
                        )}
                        <StyledContent>
                            <RichText htmlString={excerpt} textVariant="base" />
                        </StyledContent>
                    </Padder>
                    {link && (
                        <Link to={link.url}>
                            <Button variant="light">
                                <Text
                                    textVariant="button"
                                    css={{ fontSize: '1rem' }}
                                >
                                    {link.title}
                                </Text>
                            </Button>
                        </Link>
                    )}
                </Padder>
            </Revealer>
        </StyledPostCard>
    )
}

export default PostCard
