import Container from 'src/components/_layout/Container/Container'
import {
    StyledItem,
    StyledItemContent,
    StyledItemImage,
    StyledRoomsListing,
} from './RoomsListing.style'
import { CmsImage } from 'src/types'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Image from 'src/components/atoms/Image/Image'
import Padder from 'src/components/_layout/Padder/Padder'
import Text from 'src/components/atoms/Text/Text'
import { Link } from 'react-router-dom'
import Button from 'src/components/atoms/Button/Button'
import useLabel from 'src/hooks/useLabel'

type RoomsListingProps = {
    roomsListing: Array<{
        image: CmsImage
        title: string
        excerpt: string
        link: string
    }>
}

const RoomsListing: React.FC<RoomsListingProps> = ({ roomsListing }) => {
    const label = useLabel()
    return (
        <Container>
            <StyledRoomsListing as={Padder} top="md">
                {roomsListing.map(({ image, title, excerpt, link }) => (
                    <FlexWrapper
                        as={StyledItem}
                        direction={{
                            '@initial': 'columnReverse',
                            '@md': 'row',
                        }}
                        justify={{ '@initial': 'start', '@md': 'between' }}
                        align={{ '@initial': 'start', '@md': 'center' }}
                    >
                        <StyledItemContent
                            as={Padder}
                            top="sm"
                            css={{ '@mdDown': { padding: '$sm 0 $lg' } }}
                        >
                            <Text textVariant="heading3" color="primary">
                                {title}
                            </Text>
                            <Text textVariant="base2" className="line">
                                {excerpt}
                            </Text>
                            <Link to={link}>
                                <Button variant="light">
                                    {label('discover')}
                                </Button>
                            </Link>
                        </StyledItemContent>
                        <StyledItemImage>
                            <Image src={image.url} />
                        </StyledItemImage>
                    </FlexWrapper>
                ))}
            </StyledRoomsListing>
        </Container>
    )
}

export default RoomsListing
