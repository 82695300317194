import { styled } from 'src/styles/stitches.config'

export const StyledItem = styled('div', {
    position: 'relative',
    '@lg': {
        width: '50%',
    },
})

export const StyledItemContent = styled('div', {
    zIndex: 2,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: '$lg',
})
