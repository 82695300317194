import { Link } from 'react-router-dom'
import Container from 'src/components/_layout/Container/Container'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Padder from 'src/components/_layout/Padder/Padder'
import Button from 'src/components/atoms/Button/Button'
import Image from 'src/components/atoms/Image/Image'
import RichText from 'src/components/atoms/RichText/RichText'
import Text from 'src/components/atoms/Text/Text'
import Revealer from 'src/components/helpers/Revealer/Revealer'
import { CmsImage, CmsLink } from 'src/types'
import Contacts, { ContactsProps } from '../Contacts/Contacts'
import {
    StyledContentWrapper,
    StyledDescription,
    StyledImageContent,
    StyledImageWrapper,
} from './ImageLaunch.style'

type ImageLaunchProps = {
    title: string
    imageTitle?: string
    subtitle: string
    description: string
    imageDescription: string
    image: CmsImage
    link?: CmsLink
    inverted?: boolean
} & Partial<ContactsProps>

const ImageLaunch: React.FC<ImageLaunchProps> = ({
    title,
    imageTitle,
    subtitle,
    description,
    imageDescription,
    image,
    link,
    inverted,
    address,
    phone,
    email,
}) => {
    return (
        <Container>
            <FlexWrapper
                justify="between"
                align={{
                    '@md':
                        !!imageTitle || !!address || !!phone || !!email
                            ? 'start'
                            : 'center',
                }}
                direction={{
                    '@initial': 'column',
                    '@md': inverted || true ? 'rowReverse' : 'row',
                }}
            >
                <StyledImageWrapper>
                    <Padder top="md">
                        {imageTitle && (
                            <Text
                                as="h4"
                                textVariant="heading3"
                                color="primary"
                                css={{ fontWeight: '$normal' }}
                                dangerouslySetInnerHTML={{
                                    __html: imageTitle,
                                }}
                            />
                        )}
                        <StyledImageContent>
                            <Image src={image.url} />
                        </StyledImageContent>
                        {imageDescription && (
                            <StyledDescription>
                                <RichText
                                    textVariant="base2"
                                    htmlString={imageDescription}
                                />
                            </StyledDescription>
                        )}
                    </Padder>
                </StyledImageWrapper>
                <StyledContentWrapper>
                    <Padder top="md">
                        <Revealer>
                            <Text
                                as="h4"
                                textVariant="heading3"
                                color="primaryDark"
                                dangerouslySetInnerHTML={{ __html: title }}
                            />
                            <Text
                                textVariant="heading3"
                                color="primaryDark"
                                css={{ fontWeight: '$normal' }}
                                dangerouslySetInnerHTML={{ __html: subtitle }}
                            />
                        </Revealer>
                        <Revealer>
                            <Padder top="lg">
                                <StyledDescription>
                                    {description && (
                                        <RichText
                                            textVariant="base2"
                                            htmlString={description}
                                        />
                                    )}
                                    {(email || address || phone) && (
                                        <Contacts
                                            email={email || ''}
                                            address={address || ''}
                                            phone={phone || ''}
                                        />
                                    )}
                                </StyledDescription>
                                {link && (
                                    <Link to={link.url}>
                                        <Button variant="light">
                                            {link.title}
                                        </Button>
                                    </Link>
                                )}
                            </Padder>
                        </Revealer>
                    </Padder>
                </StyledContentWrapper>
            </FlexWrapper>
        </Container>
    )
}

export default ImageLaunch
