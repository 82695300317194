import { API_URL } from 'src/constants'

export const fetchSettingsFromServer = async (language: string) => {
    const response = await fetch(`${API_URL}/app?lang=${language}`);
    return response.json();
};

export const fetchMenuFromServer = async (language: string) => {
    const response = await fetch(`${API_URL}/menu?lang=${language}`);
    return response.json();
};
