import { styled } from 'src/styles/stitches.config'

export const StyledImageLaunch = styled('div', {})

export const StyledImageWrapper = styled('div', {
    position: 'relative',
    width: '100%',
    '@lg': {
        width: 'calc(9 * $colWidth)',
    },
})

export const StyledImageContent = styled('div', {
    position: 'relative',
    width: '100%',
    '&::after': {
        position: 'absolute',
        bottom: '-5%',
        left: '-5%',
        width: '50%',
        paddingBottom: '30%',
        backgroundColor: '$primary',
        content: '',
        zIndex: 0,
    },

    '&::before': {
        content: '',
        position: 'absolute',
        width: '$colWidth',
        height: '$colWidth',
        top: '0',
        right: '0',
        zIndex: 2,
        maskImage: `url(https://www.aurosavacanze.it/wp-content/themes/progetty/static/media/shape.33954c0e.svg)`,
        backgroundColor: '$primary',
        transform: 'translate(50%, -50%)',
    },
    '> img': {
        width: '100%',
        height: 'auto',
        position: 'relative',
        zIndex: 1,
    },
})

export const StyledHorizontalImageWrapper = styled('div', {
    position: 'relative',
    zIndex: 2,
    gridColumn: '1 / 3',
    gridRowStart: 2,
    ratio: {
        w: 3,
        h: 2,
    },
})

export const StyledDescription = styled('div', {
    color: '#707070',
    width: '80%',

    h4: {
        fontFamily: '$secondary',
        fontWeight: '$medium',
        lineHeight: 1,
        fontSize: '2rem',
        color: '$primary',
        marginBottom: '$sm',
    },

    '@lg': {
        width: '100%',

        '.columns': {
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            '>div': {
                width: '40%',
                lineHeight: '1.4',
                fontSize: '1.1rem',
            },
        },
    },
    '@lgDown': {
        '.columns': {
            '> div': {
                width: 'calc(100vw - 4.5rem)',
                marginBottom: '$lg',
            },
        },
    },
})

export const StyledContentWrapper = styled('div', {
    '@lg': {
        width: 'calc(10 * $colWidth)',
        paddingRight: 'calc(2 * $colWidth)',
    },
    '@mdDown': {
        paddingTop: '$md',
    },

    variants: {
        inverted: {
            true: {
                paddingRight: '0',
                paddingLeft: 'calc(2 * $colWidth)',
            },
        },
        grid: {
            true: {
                '@md': {
                    width: 'calc(8 * $colWidth)',
                },
            },
            false: {
                [`${StyledDescription}`]: {
                    width: '100%',
                },
            },
        },
    },
})
