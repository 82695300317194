import { motion } from 'framer-motion'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Text from 'src/components/atoms/Text'

const NotFound: React.FC = () => (
    <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ ease: 'easeOut', duration: 0.6 }}
    >
        <FlexWrapper
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
            }}
            align="center"
            justify="center"
            direction="column"
        >
            <Text textVariant="heading1">404</Text>
            <Text textVariant="heading3">Not found</Text>
        </FlexWrapper>
    </motion.div>
)

export default NotFound
