export const fluidSize = (
	minFont: number, //px value without 'px'
	maxFont: number, //px value without 'px'
	minScreen: number, //px value without 'px'
	maxScreen: number //px value without 'px'
) =>
	`calc(${minFont}px + (${maxFont - minFont}) * (100vw - ${minScreen}px)/(${
		maxScreen - minScreen
	}));`;

const styleUtils = {
	ratio: ({ w, h }: { w: number; h: number }): any => ({
		position: 'relative',
		'&::before': {
			content: '',
			display: 'block',
			width: '100%',
			paddingBottom: `${(h / w) * 100}%`,
		},
		'> *': {
			position: 'absolute',
			top: '0',
			left: '0',
			width: '100%',
			height: '100%',
		},
	}),
};

export default styleUtils;
