import RichText from 'src/components/atoms/RichText/RichText'
import Revealer from 'src/components/helpers/Revealer/Revealer'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Padder from 'src/components/_layout/Padder/Padder'
import { StyledContent } from './Content.style'

type ContentProps = {
    paragraphs: Array<{ paragraph: string }>
}

const Content: React.FC<ContentProps> = ({ paragraphs }) => {
    return (
        <FlexWrapper
            direction={{ '@initial': 'column', '@md': 'row' }}
            as={Padder}
            left={{ '@md': 'md' }}
            top={{ '@initial': 'md', '@md': 'zero' }}
            justify="between"
            css={{ width: '100%' }}
        >
            {paragraphs.map(({ paragraph }, index) => (
                <Revealer key={index} style={{ width: '100%' }}>
                    <RichText
                        htmlString={paragraph}
                        textVariant="base2"
                        fontWeight="$light"
                    />
                </Revealer>
            ))}
        </FlexWrapper>
    )
}

export default Content
