import { useResponsive } from 'ahooks'
import { useSelector } from 'react-redux'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Padder from 'src/components/_layout/Padder/Padder'
import { getFooter } from 'src/selectors/settings'
import { getLinkComponent } from 'src/utils/menu'
import {
    StyledFooter,
    StyledFooterContent,
    StyledFooterLogo,
} from './Footer.style'
import RichText from 'src/components/atoms/RichText/RichText'

type FooterProps = {
    disabled?: boolean
}

const Footer: React.FC<FooterProps> = ({ disabled }) => {
    const { image, logo, description, menu1 } = useSelector(getFooter)

    const responsiveInfo = useResponsive()

    return (
        <StyledFooter css={image && { backgroundImage: `url(${image.url})` }}>
            <StyledFooterLogo src={logo?.url} />
            <StyledFooterContent>
                <Padder
                    as={FlexWrapper}
                    justify="center"
                    align="center"
                    left={{ '@md': 'md' }}
                    top={{ '@mdDown': 'md' }}
                    direction={{ '@initial': 'column', '@md': 'row' }}
                >
                    <RichText htmlString={description || ''} />
                    <FlexWrapper
                        as={Padder}
                        direction={{ '@initial': 'column', '@md': 'row' }}
                        left={{ '@initial': 'zero', '@md': 'sm' }}
                        top={{ '@initial': 'xxs', '@md': 'zero' }}
                        css={{
                            '@mdDown': {
                                width: '100%',
                            },
                        }}
                    >
                        {menu1?.items?.map(getLinkComponent)}
                    </FlexWrapper>
                </Padder>
            </StyledFooterContent>
        </StyledFooter>
    )
}

export default Footer
