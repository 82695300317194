import Text from 'src/components/atoms/Text/Text'
import { styled } from 'src/styles/stitches.config'

export const StyledFooter = styled('div', {
    paddingBottom: '$md',
    position: 'relative',
    height: 'auto',
    padding: '$md $colWidth',
    textAlign: 'center',
    '@md': {
        textAlign: 'left',
        display: 'flex',
        height: '80vh',
        flexDirection: 'column-reverse',
    },
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    color: '$white',
    '&::before': {
        content: '',
        position: 'absolute',
        width: 'calc(2 * $colWidth)',
        height: 'calc(2 * $colWidth)',
        top: -1,
        left: '50%',
        zIndex: 2,
        maskImage: `url(https://www.aurosavacanze.it/wp-content/themes/progetty/static/media/shape.33954c0e.svg)`,
        backgroundColor: '$white',
        transform: 'translate(-50%, -50%)',
    },
})

export const StyledFooterLogo = styled('img', {
    width: 200,
    '@md': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: 'calc(3.5 * $colWidth)',
        height: 'auto',
        transform: 'translate(-50%, -50%)',
    },
})

export const StyledFooterContent = styled('div', {
    textAlign: 'center',
    p: {
        marginBottom: '0!important',
    },

    '@mdDown': {
        [`${Text}`]: {
            textAlign: 'center',
        },
    },
})
