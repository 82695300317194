import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { motion } from 'framer-motion'
import { getSettings } from 'src/selectors/settings'

import { StyledLink, StyledLogo } from './Logo.style'

const Logo: React.FC = (props) => {
    const { logo, language } = useSelector(getSettings)

    return (
        <StyledLink as={Link} to={`/${language}`}>
            <StyledLogo src={logo} />
        </StyledLink>
    )
}

export default Logo
