import Button from 'src/components/atoms/Button/Button'
import { Social } from 'src/components/molecules/SiteLinks/SiteLinks.style'
import { styled } from 'src/styles/stitches.config'

export const StyledHeader = styled('div', {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 21,
    paddingTop: '$sm',
    paddingBottom: '$sm',
    color: '$primaryDark',
    backgroundColor: 'transparent',
    transition: 'background-color .1s linear',
    [`${Button}`]: {
        borderColor: '$primaryDark',
        color: '$primaryDark',
        fontWeight: '$medium',
    },

    '.logo-svg': {
        fill: '$primaryDark',
    },

    '&.light': {
        backgroundColor: 'transparent',
        color: '$white',
        [`${Button}`]: {
            borderColor: '$white',
            color: '$white',
            fontWeight: '$medium',
        },

        [`${Social}`]: {
            backgroundColor: '$white',
            color: '$primaryDark',
        },

        '.logo-svg': {
            fill: '$white',
        },
    },
})
