import { CmsImage } from 'src/types'
import SwiperInstance from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { useResponsive } from 'ahooks'
import { useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { SliderWrapper, StyledHomeSlider } from './HomeSlider.style'
type HomeSliderProps = {
    items: Array<{
        image: CmsImage
        title: string
        subtitle: string
    }>
}

const HomeSlider: React.FC<HomeSliderProps> = ({ items }) => {
    const swiperRef = useRef<SwiperInstance>()
    const [currentSlide, setCurrentSlide] = useState(0)
    const responsiveInfo = useResponsive()
    const { ref, inView } = useInView({
        /* Optional options */
        threshold: 0,
    })

    // useEffect(() => {
    //     if (inView) {
    //         document.querySelector('#header')?.classList.add('light')
    //     } else {
    //         document.querySelector('#header')?.classList.remove('light')
    //     }
    // }, [inView])

    return (
        <StyledHomeSlider ref={ref}>
            <SliderWrapper
                as={Swiper}
                slidesPerView={1}
                onSwiper={(swiper) => {
                    swiperRef.current = swiper
                }}
                allowTouchMove={items?.length >= 2}
                loop={items?.length >= 2}
                onSlideChange={(swiper) => setCurrentSlide(swiper.realIndex)}
                css={{ pointerEvents: items?.length > 2 ? 'all' : 'none' }}
            >
                {items.map(({ image: { id, url, alt } }, index) => (
                    <SwiperSlide key={id || index}>
                        <img src={url} alt={alt} />
                    </SwiperSlide>
                ))}
            </SliderWrapper>
        </StyledHomeSlider>
    )
}

export default HomeSlider
