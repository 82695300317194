import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type LayerType = {
    id: string
    isActive: boolean
}

const initialState: Array<LayerType> = []

const layersSlice = createSlice({
    name: 'layers',
    initialState,
    reducers: {
        createLayer: (state, action: PayloadAction<LayerType>) => [
            ...state,
            action.payload,
        ],
        toggleLayer: (state, action: PayloadAction<{ layerId: string }>) => {
            const layer = state.find(({ id }) => action.payload.layerId === id)
            if (!layer) {
                return [...state]
            }
            return [
                ...state.filter(({ id }) => action.payload.layerId !== id),
                { ...layer, isActive: !layer.isActive },
            ]
        },
        showLayer: (state, action: PayloadAction<{ layerId: string }>) => {
            const layer = state.find(({ id }) => action.payload.layerId === id)
            if (!layer) {
                return [...state]
            }
            return [
                ...state.filter(({ id }) => action.payload.layerId !== id),
                { ...layer, isActive: true },
            ]
        },
        hideLayer: (state, action: PayloadAction<{ layerId: string }>) => {
            const layer = state.find(({ id }) => action.payload.layerId === id)
            if (!layer) {
                return [...state]
            }
            return [
                ...state.filter(({ id }) => action.payload.layerId !== id),
                { ...layer, isActive: false },
            ]
        },
        deleteLayer: (state, action: PayloadAction<{ layerId: string }>) =>
            state.filter(({ id }) => action.payload.layerId !== id),
    },
})

export const { createLayer, deleteLayer, hideLayer, toggleLayer, showLayer } =
    layersSlice.actions
export default layersSlice.reducer
