import Text from 'src/components/atoms/Text/Text'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Padder from 'src/components/_layout/Padder/Padder'
import { StyledReview, StyledReviews, StyledStar } from './Reviews.style'
import { IoMdStarOutline, IoMdStar } from 'react-icons/io'
import Container from 'src/components/_layout/Container/Container'
import RichText from 'src/components/atoms/RichText/RichText'

type ReviewsProps = {
    items: Array<{ title: string; vote: string; review: string; name: string }>
}

const Reviews: React.FC<ReviewsProps> = ({ items }) => {
    return (
        <Container
            css={{ '@mdDown': { paddingTop: '$lg', paddingBottom: '$xl' } }}
        >
            <FlexWrapper css={{ flexWrap: 'wrap', gap: 'calc(2 * $colWidth)' }}>
                {items.map(({ title, vote, review, name }, index) => (
                    <StyledReview key={index}>
                        <Padder top="xs">
                            <Text textVariant="heading4">{title}</Text>
                            <FlexWrapper>
                                {Array(+vote)
                                    .fill(0)
                                    .map((_, index) => (
                                        <StyledStar
                                            key={index}
                                            fill={index < +vote}
                                        >
                                            <IoMdStar key={index} />
                                        </StyledStar>
                                    ))}
                            </FlexWrapper>
                            <RichText htmlString={review} />
                            <Text textVariant="base">{name}</Text>
                        </Padder>
                    </StyledReview>
                ))}
            </FlexWrapper>
        </Container>
    )
}

export default Reviews
