import { styled } from 'src/styles/stitches.config'

export const StyledDescription = styled('div', {
    width: '80%',
})

export const StyledChunk = styled('div', {
    '@mdDown': {
        marginTop: '$md',
    },
    '@md': {
        width: 'calc(8 * $colWidth)',
        '&:nth-child(2n)': {
            paddingTop: 0,
        },
    },
})

export const StyledImageWrapper = styled('div', {
    position: 'relative',
})

export const StyledCardTite = styled('div', {
    position: 'absolute',
    left: 0,
    bottom: 0,
    padding: '$xs $sm',
    backgroundColor: '$primary',
    color: '$white',
})

export const StyledCard = styled('div', {})
