import { styled } from 'src/styles/stitches.config'

export const StyledWrapper = styled('div', {
    paddingBottom: 'calc(2 * $colWidth)',
})

export const StyledPageTitle = styled('div', {
    position: 'relative',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '25rem',
    height: '50vh',
    '@md': {
        '&::before': {
            content: '',
            position: 'absolute',
            width: 'calc(2 * $colWidth)',
            height: 'calc(2 * $colWidth)',
            top: '100%',
            left: '50%',
            zIndex: 2,
            maskImage: `url(https://www.aurosavacanze.it/wp-content/themes/progetty/static/media/shape.33954c0e.svg)`,
            backgroundColor: '$white',
            transform: 'translate(-50%, -50%)',
        },
    },
})

export const StyledPageTitleContent = styled('div', {
    display: 'flex',
    alignItems: 'flex-end',

    position: 'absolute',
    top: '100%',
    left: 'calc(3 * $colWidth)',
    width: 'calc(3.5 * $colWidth)',
    height: 'calc(3.5 * $colWidth)',
    minWidth: 'fit-content',
    backgroundColor: '$primary',
    padding: '$sm',
    transform: 'translateY(-50%)',
    color: '$white',

    '@mdDown': {
        alignItems: 'center',
        left: '2rem',
        padding: '$xs',
        width: 'fit-content',
        height: 'fit-content',
    },
})
