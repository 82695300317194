/* eslint-disable react-hooks/exhaustive-deps */
import { CSSProperties, useCallback, useEffect } from 'react'

import { useResponsive } from 'ahooks'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

import { StyledRevealer } from './Revealer.style'

const Revealer: React.FC<{ style?: CSSProperties; forceReveal?: boolean }> = ({
    children,
    style,
    forceReveal,
}) => {
    const [ref, inView, entry] = useInView({
        triggerOnce: true,
        threshold: 0.25,
    })
    const controls = useAnimation()
    const responsiveInfo = useResponsive()
    const doReveal = useCallback(() => {
        controls.start({
            y: ['100px', '0px'],
            opacity: 1,
            transition: {
                duration: 1,
                ease: 'easeInOut',
            },
        })
    }, [])

    useEffect(() => {
        if (!!entry && inView) {
            doReveal()
        }
    }, [entry, inView])

    useEffect(() => {
        if (
            !!entry &&
            entry.target.getBoundingClientRect().height >= window.innerHeight
        ) {
            doReveal()
        }
    }, [responsiveInfo, entry])
    return (
        <StyledRevealer
            as={motion.div}
            ref={ref}
            animate={!forceReveal && controls}
            style={style}
            forceReveal={forceReveal}
        >
            {children}
        </StyledRevealer>
    )
}

export default Revealer
